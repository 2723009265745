.topSection {
  padding: 10px 0;
}

.grid {
  @media (--md-up) {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}

.filters {
  display: flex;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
}

.filterBox {
  display: flex;
  flex-wrap: wrap;
}

.sort {
  display: flex;
  align-items: center;
  grid-area: 1 / 2 / 2 / 3;

  @media (--md-down) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @media (--sm-down) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .list {
    margin-right: 0;
  }
}

.selectWrapper {
  display: flex;
}

.label {
  text-transform: uppercase;
  color: var(--grey-500);

  span {
    color: var(--text-primary);
  }
}

.list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;

  &:not(:last-child) {
    @media (--sm-up) {
      margin-right: 60px;
    }
  }

  @media (--sm-down) {
    margin-left: 10px;
    gap: 10px;
  }
}

.options {
  display: flex;
  column-gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  grid-area: 2 / 1 / 3 / 3;

  @media (--md-down) {
    display: block;
  }

  .optionsList {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
}

.filterOption {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-right: 10px;
}

.metalBtn {
  &.active {
    .imgWrap,
    .titleWrap {
      border-color: var(--primary-main);
    }
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .imgWrap {
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all ease 0.25s;
    width: 40px;
    height: 40px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      inset: 5px;
      border-radius: 50%;
      background: var(--background-default);
      transition: all ease 0.25s;
    }

    &.bandImg {
      &::after {
        display: none;
      }
    }
  }

  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid transparent;
    border-color: var(--secondary-main);
    transition: all ease 0.25s;
    width: 40px;
    height: 40px;
    position: relative;
    text-transform: lowercase;
    font-size: 12px;
  }
}

.shape {
  font-size: 40px;
  opacity: 0.5;
  transition: all ease 0.25s;

  &.active {
    opacity: 1;
  }
}

.slider {
  width: 300px;

  @media (--md-up) {
    width: 500px;
  }
}

.filterValues {
  display: flex;
  gap: 10px 15px;
  flex-wrap: wrap;
  grid-area: 3 / 1 / 4 / 3;

  .value {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0;
    opacity: 0.5;
    transition: all ease 0.25s;
    white-space: nowrap;

    &:hover {
      opacity: 1;
    }
  }
}
