.imgWrap {
  margin-right: 112px;
  max-width: 330px;
  height: 330px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-main);
  position: relative;

  @media (--xl-down) {
    margin-right: 20px;
  }

  @media (--lg-down) {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .warning {
    font-family: var(--font-secondary);
    text-align: center;
    padding: 20px;
  }

  .hint {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font-secondary);
  }
}
