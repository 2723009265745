.loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
}

.diamondImgWrap {
  position: absolute;
  bottom: 29%;
  left: 33%;
  transform: translate(-50%, 50%);
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.textInfo {
  position: absolute;
  text-transform: uppercase;
  translate: -50% 0;
  bottom: 18px;
  white-space: nowrap;
}
